.carrierQuote__agreementQuote .card-header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}
.carrierQuote__agreementQuote .card-body pre {
    font-size: 12px;
    white-space: pre-wrap;
    transition-duration: 0.2s;
}
.agreementMessage p {
    margin: 0;
}
.arrow-down {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid gray; /* Цвет стрелки */
    display: inline-block;
    margin: 10px;
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid gray; /* Цвет стрелки */
    display: inline-block;
    margin: 10px;
}

.carrierQuote__mainForm {
    display: flex;
    margin-bottom: 15px;
}
.carrierQuote__mainForm-div {
    width: 70%;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px
}

.carrierQuote__description .card-header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.carrierQuote__description .card-body pre {
    font-size: 12px;
    white-space: pre-wrap;
    max-height: 400px;
    transition-duration: 0.2s;
}

.carrierQuote__quoteInfo {
    width: 30%;
}

