.counterButton {
  padding: 4px 12px;
  border: 1px solid black;
  font-size: 18px;
  font-weight: 900;
  border-radius: 4px;
  cursor: pointer;
}

.minusButton {
  background: red;
}

.plusButton {
  background: green;
}

.currentBidText {
  border: 1px solid #192744 !important;
  background: transparent !important;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-right: none !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.currentBidInput {
  border: 1px solid #192744 !important;
  font-size: 16px !important;
  text-align: center !important;
  font-weight: 600 !important;
}

.currentBidSign {
  border: 1px solid #192744 !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-left: none !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}
