.row {
    width: 100%;
    padding: 8px 0;
    border-bottom: 1px solid black;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    @media (max-width: 1200px) {
        display: flex;
        flex-direction: column;
        border: none;
        padding: 0;
    }
}

.lastRow {
    border: none;
}

.request {
    width: 100%;
    height: fit-content;
}

.requestRow {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 1200px) {
        padding: 8px 0;
        border-bottom: 1px solid black;
    }
}





