.data-table, .data-table input {
	font-size: 0.75rem;
}

.data-table .table td.overflow {
	overflow: auto;
	max-width: 50px;
}

.overflow::-webkit-scrollbar {
	width: 0;
}
.context-menu-item:hover {
	background-color: #ffffff;
	text-decoration: none;
}

.context-menu {
	-webkit-box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.14);
	-moz-box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.14);
	box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.14);
}

.data-table .table td {
	padding: 3px 5px !important;
}

.data-table th.id, .data-table td.id {
	width: 50px;
	text-align: center
}

.data-table th.yes-no, .data-table td.yes-no {
	width: 100px;
	text-align: center
}

.data-table .card-header {
	padding: .25rem 1.25rem;
}

.data-table .card-header button.link {
	font-size: 0.75rem;
	padding-left: 0;
}

.data-table .card-header .title {
	top: 3px;
	font-weight: 500;
	font-size: 1.1rem;
}

.data-table .table th {
	text-align: center;
	vertical-align: middle;
}

.data-table .table th .fa-filter {
	cursor: pointer;
	color: grey;
	float: right;
	padding: 4px 0 5px 5px;
}
.data-table .table th .fa-filter.selected {
	color: green;
}
.data-table .table th .fa-times {
	cursor: pointer;
	color: red;
	float: right;
	padding: 4px 0 5px 5px;
}

.columns-selector {
	display: inline-block;
}

.columns-selector a {
	padding-left: 0;
	top: 1px;
	position: relative;
}

.columns-selector i {
	padding: 0 5px 0 5px;
	display: inline;
}

.columns-selector i.hidden {
	width: 25px;
	display: inline-block;
}

.columns-selector .dropdown-menu {
	overflow-y: scroll;
	max-height: 300px;
}

.columns-selector .hover {
	border-top: 2px solid #ccc;
}

.data-table input.search {
	width: 125px;
	margin-right: 0.75rem;
	display: inline-block;
}

.data-table .mark {
	background-color: initial
}

.data-table .actions i {
	cursor: pointer;
	color: #777;
}

.data-table .th-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-between;
    justify-content: flex-end;
    align-items: center;
}

.data-table .th-container .th-title {
    flex-grow: 1;
}

.data-table th.sortable  {
	cursor: pointer;
}

.data-table .card-header button {
	margin-left: 3px;
}

.filter .form-check {
	padding-left: 2.25rem
}

.data-table .filter {
	z-index: 99;
	border: solid 1px #777;
	position: fixed;
	background-color: #fff;
	padding: 10px;
}

.data-table .filter .body {
	height: 100%;
    margin-bottom: -35px;	
}

.data-table .filter label {
	display: block;
}
.data-table .select-row {
	background-color: rgb(220, 52, 43, 0.4) !important;
}