.sortable {
    cursor: pointer;
}

.table th {
    text-align: center;
    white-space: nowrap;
}

.th.id, td.id {
    width: 50px;
    text-align: center;
}

.table td.overflow {
    overflow: auto;
    max-width: 50px;
}

.table td.overflow::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.overflow::-webkit-scrollbar {
    width: 0;
}