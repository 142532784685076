.calculate-label {
    margin-top: 5px;
    margin-bottom: 2px;
}

.motivation-btn {
    width: 90px;
}

.table-content-edit:focus {
    outline: none;
    border: 0;
    font-weight: bold;
    background-color: RGBA(0,0,0,0);
}

.table-cell-sm {
    max-width: 70px;
    min-width: 70px;
}

.table-cell-md {
    max-width: 90px;
    min-width: 90px;
}

.table-cell-lg {
    max-width: 120px;
    min-width: 120px;
}

.table-cell-xlg {
    max-width: 180px;
    min-width: 180px;
}

.table-content-static {
    width: 100%;
    min-height: 20px;
}

.table-content-static-head {
    font-weight: bolder;
}

.table-content-static-total {
    color: #00af10;
    font-weight: bolder;
}

.table-cell {
    background-color: white;
}

.wage {
    color: darkseagreen;
}

.wage:hover {
    cursor: pointer;
}

.wage:active {
    color: white;
}

.modal-dialog {
    width: fit-content !important;
    max-width: 100% !important;
}

.paidQuotes:hover {
    cursor: pointer;
    text-decoration: underline;
}

.change-month {
    border: 0;
    background-color: rgba(0,0,0,0);
    font-weight: bold;
}

.change-month:hover {
    color: #007bff;
}

.change-month:disabled:hover {
    color: #cccccc;
}

.wages-table-thead {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}

.table-cell-quotes-number {
    max-width: 70px;
    min-width: 70px;
    color: #007bff;
    cursor: pointer;
}

.department-row-total {
    background-color: #eaeaea;
    cursor: pointer;
}

.wage-reports {
    overflow-x: auto;
    height: 735px;
    width: auto;
}

.wage-reports-table {
    border-collapse: collapse;
    width: 100%;
}

.container-position-header {
    max-width: 220px;
    min-width: 220px;
}

.container-position-body {
    max-width: 220px;
    min-width: 220px;
    padding: 0 !important;
}

.motivation-date-picker {
    border: 0;
    width: 100%;
}

.motivation-date-picker:focus {
    outline: none;
    border: 0;
    font-weight: bold;
}