.alert-color {
    color: #842029;
    background-color: #f8d7da !important;
}

.alert-center-item {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.alert-center-link {
    position: relative;
    height: 1rem;
    display: flex;
    align-items: center;
    padding-right: 0;
    padding-left: 0;
}

.alert-center-link i {
    color: #5a5c69;
    font-size: medium;
    margin-top: 13px;   
}

a.alert-center-link:hover {
	text-decoration: none;
}

a.alert-center-link i:hover {
    color: #4e73df;
}

.badge-counter {
	position: absolute;
    transform: scale(.95);
    transform-origin: top right;
    right: -0.8rem;
    margin-top: -0.25rem;
}

.dropdown-menu {
    position: absolute !important;
    float: none;
    max-height: calc(21rem - 1px);
    overflow-x: hidden;
    overflow-y: overlay;
    margin-top: 20px !important;  
}

div.topbar {
    height: 1rem !important;
}

.topbar .dropdown-list {
    width: 24rem !important;
	overflow: auto;
}

.dropdown-item {
    line-height: 1.1rem !important;
    cursor: pointer;
}

.loader {
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;	
}

table.no-line-break td {
    white-space: nowrap;
}

.clear-icon {
    color: red;
    cursor: pointer;
}

.copy-icon {
 color: #868e96;
 cursor: pointer;
}

.dropdown-menu-show {
    margin-right: 130px !important;
}