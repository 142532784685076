.wrapper {

}

.price {
    font-size: 22px;
    font-weight: 800;
}

.sign {
    font-size: 19px;
}

.text {
    font-size: 22px;
}

