.login label {
	display: block;
	margin-top: 10px;
}
.sidebar-dark, .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected, .react-datepicker__time-list-item--selected {
	background-color: #192744 !important;
}
.form-control:hover, .form-control.react-datepicker-ignore-onclickoutside {
	border: 1px solid rgb(25, 39, 68, 0.6) !important;
}
.form-control, .custom-control-input:focus~.custom-control-label::before {
	border-color: #192744 !important;
	border: 1px solid rgb(25, 39, 68, 0.2) !important;
}
.custom-control-input:checked~.custom-control-label::before {
	border-color: #192744 !important;
	background-color: #192744 !important;
	box-shadow: 0 0 0 .2rem rgb(25, 39, 68, 0.2) !important;
}
.form-control:focus {
	box-shadow: 0 0 0 .2rem rgb(25, 39, 68, 0.2) !important;
}
.nav-item.nav-link.active {
	background-color:  rgb(25, 39, 68, 1) !important;
	color: white !important;
}
.nav-item.nav-link {
	color: #414E62 !important;
}
.nav-item .nav-link span, .sidebar-brand-text {
	color: rgba(255, 255, 255, .8) !important;
}
.sidebar-brand {
	height: auto !important;
	padding:  5px !important;
}
.sidebar-brand-text {
	font-size: 20px !important;
}
.sidebar-brand-icon {
	background: url(./logo_transport.svg) 50% 50% no-repeat;
	padding: 35px 35px;
	background-size: 120px;
}
.login .card-container.card {
	max-width: 350px !important;
	padding: 40px 40px;
}

.title {
	color: #1B1B1B !important;
}
.th-title, .topbar .nav-item .nav-link span {
	color: #414E62 !important;
}
.dropdown-item {
	background-color: #fff !important;
	color: #192744 !important;
}
.btn-outline-primary {
	background-color: #fff !important;
	border-color: #192744 !important;
	color: #192744 !important;
	box-shadow: none !important;
}
.btn-outline-primary:hover, .btn.btn-outline-primary.active {
	background-color: #192744 !important;
	color: white !important;
}
a {
	color: #192744 !important;
}
.btn:focus {
	box-shadow: 0 0 0 .2rem rgb(25, 39, 68, 0.2) !important;
}
.sidebar .nav-item .collapse .collapse-inner .collapse-item.active, .sidebar .nav-item .collapsing .collapse-inner .collapse-item.active {
	color: #192744 !important;
}
.btn-link {
	color: #DC342B !important;
}
.react-datepicker__input-container .react-datepicker__close-icon::after {
	background-color: #192744 ;
}
.btn.bg-primary, .btn.btn-primary, btn btn-link {
	background-color: #192744 !important;
	border: 0 !important;
}
.dropdown-toggle {
	color: #192744 !important;
}
.notices__header-item.bg-primary {
	background-color: #192744 !important;
}
.btn.bg-primary:hover {
	background-color: #DC342B !important;
}
.page-item.active .page-link {
	background-color: #192744 !important;
	color: white !important;
	border: 0 !important;
}
.page-item .page-link {
	color: #192744 !important;
}
.page-item .page-link:focus {
	box-shadow: none !important;
}
.registration .card-container.card {
	max-width: 600px !important;
	padding: 40px;
}

.registration .card,
.login .card {
	background-color: #f7f7f7;
	padding: 20px 25px 30px;
	margin: 0 auto 25px;
	margin-top: 50px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	-moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
	width: 96px;
	height: 96px;
	margin: 0 auto 10px;
	display: block;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

form, form .form-control-sm {
	font-size: 0.75rem;
}

form .col {
	padding-left: 10px;
	padding-right: 10px;
}

form .card-body {
	padding: 1rem;
}

form .card-footer {
    padding: .4em .75rem;
    opacity: 0.7;
}

form .card-footer {
	text-align: right;
}

.validation.alert {
	padding: 0.15rem 1.25rem;
}
.form-group .react-datepicker-wrapper {
	display: block;
}

.status-buttons a.nav-link {
	padding: 0.5rem 0.5rem;
}

.sidebar .simple {
	margin: -10px 0 !important;
}

.sidebar a.nav-link span {
	text-overflow: ellipsis;
    overflow: hidden;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-header {
	white-space: unset;
}

.navbar-nav-font {
	min-width: 14rem !important;
}

.form-check label {
	position: relative;
	top: 3px;
}

.form-group-ext {
	text-align: right !important;
	width: 100%;
	position: absolute;
	top: -2px;
	font-size: x-small !important;
	padding-right: 30px
}

.form-group-ext button {
	font-size: x-small;
}

.form-container {
	position: relative;
}

.form-disabled-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: none;
	pointer-events: none;
	z-index: 1000;
}

.form-disabled .form-disabled-overlay {
	display: block;
}

.form-disabled .form-contents {
	pointer-events: none;
	opacity: 0.8;
}

.custom-switch label {
	padding-top: 3px;
}
.route.card .card-body {
	overflow-x: scroll;
}
.import-card + .import-card {
	margin-top: 1rem;
}
.import-card .card-body {
	font-size: 0.85rem;
}
.tox-notification {
	display: none !important
}

table.selectable tbody tr.active {
	background-color: rgba(0,123,255,.075);
}

.btn.btn-xs {
	padding-top: 5px;
    padding-bottom: 5px;
}

.required-field::after {
    content: "*";
    color: red;
    margin-left: 2px;
	font-weight:bold;
}

.collapse.show {
	z-index: 4 !important;
}

.dropzone {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	border-radius: 2px;
	border: 2px dashed #eeeeee;
	background-color: #fafafa;
	color: #bdbdbd;
	outline: none;
	transition: border .24s ease-in-out;
	cursor: pointer;
}

.dropzone .drop-tip {
	color: #2484ff;
	font-size: 14px;
}

.attachments-table .actions, .addresses-table .actions .supply-table {
	display: flex;
	justify-content: center;
}

.attachments-table .actions i {
	cursor: pointer;
}

.address-add {
	font-size: 0.9rem;
	position: relative;
	top: 1px;
	left: 3px;
	cursor: pointer;
}

.address-add:hover {
	text-decoration: none;
}

.privacy-policy {
	padding: 60px 0;
}

.privacy-policy p {
	margin: 0;
	text-indent: 20px;
}


.privacy-policy .container li {
	list-style-type: none !important; /* Убираем нумерацию */
	position: relative;
}

.privacy-policy .container ol {
	counter-reset: list1; /* Инициируем счётчик */
	padding-left: 0; /* Убираем отступы */
}

.privacy-policy .container ol > li {
	counter-increment: list1; /* Увеличиваем значение счетчика */
	text-align: center; /* Центрируем текст первого уровня */
	font-weight: bold; /* Жирный текст */
	margin-left: 0; /* Убираем отступ */
}
.privacy-policy .container ol li p {
	text-align: left;
	font-weight: normal;
}


.privacy-policy .container ol > li::before {
	content: counter(list1) ". "; /* Выводим значение типа 1., 2. и т. д.*/
	visibility: visible;
	font-size: 100%;
	font-weight: bold; /* Жирная нумерация */
	display: inline-block; /* Делаем нумерацию inline */
	width: 1.5em; /* Ширина для центрирования */
	margin-left: -1.5em; /* Сдвигаем нумерацию влево */
}

.privacy-policy .container ul li {
	list-style-type: circle !important;
}

.privacy-policy .container ul {
	padding: 0;
}

.privacy-policy .container ol ol {
	counter-reset: list2; /* Инициируем счётчик вложенного списка */
	margin-left: 2em;
}

.privacy-policy .container ol ol > li {
	counter-increment: list2;
	text-align: left; /* Левое выравнивание текста */
	font-weight: normal; /* Обычный текст для вложенного списка */
	margin-left: 1.5em;
}

.privacy-policy .container ol ol > li::before {
	content: counter(list1) "." counter(list2) ". ";
	visibility: visible;
	font-size: 100%;
	font-weight: bold; /* Жирная нумерация */
	position: absolute;
}

.privacy-policy .container ol ol > li > p {
	text-align: left; /* Левое выравнивание текста для вложенных элементов */
}

.n-success,
.n-warning,
.n-danger,
.n-info,
.n-blue {
	margin: 25px;
	padding: 12px 12px 12px 37px;
	color: #333;
	border-radius: 2px;
	background: #fff;
	position: relative;
	font-weight: bold;
	font-family: Verdana, sans-serif;
	box-sizing: border-box;
	border-style: solid;
	border-color: #e3e6f0;
	border-width: 1px;
	border-left-width: 5px;
}
.n-success:before,
.n-warning:before,
.n-danger:before,
.n-info:before,
.n-blue:before {
	content: "";
	background: inherit;
	color: inherit;
	border: inherit;
	display: block;
	text-align: center;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	line-height: 48px;
	position: absolute;
	left: -25px;
	box-sizing: border-box;
	font-family: "FontAwesome";
	font-size: 22px;
}
.n-success {
	color: #32a846;
	border-left-color: #32a846;
}
.n-success:before {
	content: "✔";
}
.n-warning {
	color: #f08d3c;
	border-left-color: #f08d3c;
}
.n-warning:before  {
	content: "!";
}
.n-danger {
	color: #d9534f;
	border-left-color: #d9534f;
}
.n-danger:before  {
	content: "❌";
}
.n-info {
	color: #3d98e3;
	border-left-color: #3d98e3;
}
.n-info:before  {
	content: "ℹ";
}
.n-blue {
	color: #0659f7;
	border-left-color: #0659f7;
}
.n-blue:before  {
	content: "ℹ";
}
