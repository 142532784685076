.wrapper {

}

.price {
    font-size: 22px;
    font-weight: 800;
}

.text {
    font-size: 22px;
}

.nds {
    font-size: 18px;
}
