.confirmationAgreementAlert {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 5px !important;
    display: flex;
}
.confirmationAgreementAlert div {
    display: flex;
    gap: 10px;
}