.row-center {
    justify-content: center;
    margin-top: 1vh;
}

.row-left {
    justify-content: left;
    margin-top: 2vh;
}

.col-scroll {
    overflow-y: auto;
    max-height: 24vh;
}

.form-group-margin {
    margin: 0 1vh;
}

.search-button {
    width: 5vh;
    position: absolute;
    right: 1.3vh;
    margin-right: 1vh;
    pointer-events: all;
}