.task-comment {
    border-radius: 15px;
    width: 50%;
    margin: 0.5% 2%;
    max-height: 5%;
}

.task-comment-icon-margin {
    font-size: 15px;
    margin-left: 5px;
}

.task-icon-float {
    font-size: 15px;
    float: right;
}

.icon-comment-send {
    font-size: 30px;
    border: 1px solid #ccc;
    border-left: none;
    border-radius: 5px;
    padding: 2px 5px;
    background-color: #f2f2f2;
}

.icon-hover:hover {
    color: #3f5e9d;
}

.related-task {
    color: #007bff;
    cursor: pointer;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    max-width: 220px;
}

.related-task:hover {
    text-decoration-line: underline;
}

.related-task::-webkit-scrollbar {
    height: 5px;
}

.related-task::-webkit-scrollbar-thumb {
    background-color: #d8dee9;
    border-radius: 30px;
}

.task-file-uploader {
    height: 227px;
    overflow-y: auto;
}

.verification-table-checked {
    background-color: darkseagreen;
}

.verification-table-unchecked {
    background-color: #fe5858;
}

.verification-table-default {
    background-color: white;
}

.tiny-editor {
    position: relative;
    z-index: 0;
}
