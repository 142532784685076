.modal-form {
    font-family:"Fira Sans", sans-serif;
    color: #1c294e;
}

.modal-text-header {
    font-size: 25px;
    margin-top: 20px;
}

.modal-text-p {
    font-size: 15px;
}

.blur {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(15px);
}
