.wrapper {
    width: 100%;
    padding-bottom: 8px;
    border-bottom: 1px solid black;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.price {

}

.text {
    
}

