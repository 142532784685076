.survey-card-container {
    display: flex;
    flex-flow: nowrap;
}

.survey-card {
    width: 100%;
}

.survey-status {
    width: 35%;
}

.text-editor {
    background-color: white;
    border: 1px solid #e3e6f0;
    border-radius: 5px;
    padding: 5px 5px;
}

.card-header-danger {
    background-color: #f8d7da !important;
}

.form-card {
    background-color: #f8f9fc !important;
    border-color: #f8f9fc !important;
}

.form-card-header {
    background-color: #f8f9fc !important;
}

.shadow-bottom,
.shadow-bottom-danger {
    position: relative;
}

.shadow-bottom::after {
    content: '';
    position: absolute;
    bottom: -8px;
    width: 100%;
    height: 8px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
}

.shadow-bottom-danger::after {
    content: '';
    position: absolute;
    bottom: -8px;
    width: 100%;
    height: 8px;
    background-image: linear-gradient(to bottom, #f8d7da, transparent);
}