.auctionForm {
    width: 100%;
    max-width: 500px;
}

.buttons {
    display: flex;
    gap: 20px;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fields {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.checkboxes {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
}

.fieldsRow {
    margin-top: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}

.firstRow {
    margin-top: 0;
}

.httpError {
    color: red;
    font-size: 16px;
}

.status {
    color: black;
    font-size: 16px;
}

.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.submit {
    width: fit-content;
    margin-top: 20px;
}

/*
COMMON
*/

.row {
    max-width: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.row:first-child {
    margin-left: 0;
}