.font-title {
    font-family: "Gill Sans", sans-serif;
    font-size: 15px;
}

.font-p {
    font-family: "Gill Sans", sans-serif;
    font-size: 13px;
}

.tdAll {
    padding: 15px;
    line-height: 20px;
    color: #444441;
    border-top: 1px solid #dcdcdc;
}

.tdTitle {
    width: 22%;
}

.tdCell {
    width: 6%;
    text-align: center;
}

.arrow-color{
    color: #bdbbbb;
}

.button {
    background-color: #f1f0f0 !important;
    border: none !important;
    border-radius: 25px !important;
    text-align: center;
    box-shadow: none !important;
}

.buttonOL {
    background-color: #f6f6f6 !important;
    border: none !important;
    border-radius: 25px !important;
    text-align: center;
    box-shadow: none !important;
}

.buttonKO {
    background-color: #eeecec !important;
    border: none !important;
    border-radius: 25px !important;
    text-align: center;
    box-shadow: none !important;
}

.thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #f1efef;
    border-radius: 10px 10px 0 0;
}

.theadKO {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #eeecec;
    border-radius: 10px 10px 0 0;
}

.rowColor {
    background-color: #f1f0f0;
}

.rowColorKO {
    background-color: #d3d3d3;
}

.rowColorMarginOrGsm {
    background-color: #f6f6f6;
}


.inputStyle {
    display: block;
    width: 130%;
    font-family: "Gill Sans", sans-serif;
    font-size: 13px;
    color: #212529;
    background-color: #fff;
    border: 1px solid #bdbdbd;
    border-radius: 0.25rem;
    outline: none;

    max-width: 100px;
    max-height: 65px;
}

.tableCon {
    margin-top: 5px;
}

.menu {
    width: 300px;
    max-height: 500px;
    height: 500px;
    overflow-y: auto;
}


.colorCellGrey {
    background-color: #e3e3e3;
}

.colorCellWhite {
    background-color: #FFFFFF;
}

.tableFixHead {
    overflow: auto;
    height: 700px;

}


.tableSsp {
    border-collapse: collapse;
    width: 100%;
}

.buttonContent {
    display: flex;
    align-items: center;
}

.customButton {
    background-color: rgb(241,240,240) !important;
    outline: none !important;
    box-shadow: none !important;
    border-color:  rgb(241,240,240) !important;
}

.customButtonSuccess {
    background-color: rgb(206,255,188) !important; /* Стандартный цвет кнопки */
    outline: none !important;
    box-shadow: none !important;
    border-color:  rgb(241,240,240) !important;
}

.buttonPlanContractor {
    background-color: #d3d3d3 !important;
    border: none !important;
    border-radius: 25px !important;
    text-align: center;
    box-shadow: none !important;
}

.buttonPlanDepartment {
    background-color: #e5e2e2 !important;
    border: none !important;
    border-radius: 25px !important;
    text-align: center;
    box-shadow: none !important;
}

.buttonKoDep {
    background-color: #efecec !important;
    border: none !important;
    border-radius: 25px !important;
    text-align: center;
    box-shadow: none !important;
}

