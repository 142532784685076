.notices {
    position: absolute;
    transition-duration: 0.2s;
    right: 0px;
    top: 80px;
    width: 500px;
    margin-right: 10px;
    background-color: #fff;
    padding: 5px;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    z-index: 100;
}
.notices__link {
    color: white !important;
}
.notices__search {
    background: url(./img/search.svg) 50% 50% no-repeat;
    padding: 12px !important;
    width: 38px;
}
.notices__activeBtn {
    background-color: red !important;
}
.notices__dataFilters .col {
    padding: 0;
}
.notices__dataFilters input {
    height: 37px
}
.notices__dataFilters .form-group{
    margin: 0;
}
.notices__close {
    right: -520px
}
.NoticesBtn {
    cursor: pointer;
    position: relative;

}
.NoticesBtn:hover{
    .noticeBadge{
        animation: 1s infinite alternate noticesBadge;
    }
}
.noticeBadgeCounter {
    position: absolute;
    right: -10px;
    top: 0;
    font-weight: 600;
    background-color: red;
    border-radius: 10px;
    padding: 0 5px;
    font-size: 12px;
    color: white
}
@keyframes noticesBadge {
    10% {
        transform: rotate(10deg);
    }

    20% {
        transform: rotate(-20deg);
    }

    50% {
        transform: rotate(15deg);
    }

    75% {
        transform: rotate(-25deg);
    }

    100% {
        transform: rotate(5deg);
    }
}
.notices__date {
    overflow-x: auto;
    overflow-y: hidden;
    word-wrap: break-word;
    max-height: 30px;
}
.noticeBadge {
    background: url(./img/noticesIcon.png) 50% 50% no-repeat;
    padding: 20px;
}
.notices__header {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.225);
}
.notices__header button {
    width: 100%;
    padding: 3px 5px;
    font-weight: 500;
    transition-duration: 0.2s;
}
.notices__header button:hover {
    background-color: rgba(184, 210, 238, 0.4);
}
.notices__header div {
    display: flex;
    justify-content: space-between;
    gap: 5px
}
.notices__item-header div {
    display: flex;
    gap: 10px
}
.noticesDate .col {
    padding: 0;
    margin: 0;
    height: 35px;
}
.noticesDate {
    display: flex;
    flex-direction: column;
}
.notices__header-item {
    padding: 5px;
    border-radius: 2px;
    font-weight: 500;
    min-width: 70px;
    max-height: 30px;
    text-align: center;
}
.notices__field {
    padding: 0 5px;
    background-color: #fff;
}
.notices__link {
    width: 100%;
    overflow-x: auto;
    padding: 2px;
    cursor: pointer;
}
.notices__unViewed {
    background: url(./img/unViewed.svg) 50% 50% no-repeat;
}
.notices__viewed {
    background: url(./img/viewed.svg) 50% 50% no-repeat;
}
.notices__footer {
    display: flex;
    gap: 10px;
}
.notices__header-filters .col .form-group div {
}
.notices__delete {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: grey;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    cursor: pointer;
    transition-duration: 0.2s;
}
.notices__delete div {
    display: block;
    width: 80%;
    height: 80%;
    --weight: 1px;
    --aa: 1px; /* anti-aliasing */
    --color: white;
    border-radius: 3px;
    background:
            linear-gradient(45deg, transparent calc(50% - var(--weight) - var(--aa)), var(--color) calc(50% - var(--weight)), var(--color) calc(50% + var(--weight)), transparent calc(50% + var(--weight) + var(--aa))),
            linear-gradient(-45deg, transparent calc(50% - var(--weight) - var(--aa)), var(--color) calc(50% - var(--weight)), var(--color) calc(50% + var(--weight)), transparent calc(50% + var(--weight) + var(--aa)));
}
.notices__delete:hover {
    background-color: #dc3545;
}
.notices__delete:active {
}
.notices, .notices__field {
    border: 1px solid rgba(0, 0, 0, 0.225);
    border-radius: 4px;
}
.notices__item {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 2px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.34);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.34);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.34);
}
.notices__items::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.225);
    border-radius: 2px;
}
.notices__items::-webkit-scrollbar {
    width: 6px;
}
.notices__link::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.225);
    border-radius: 2px;
}
.notices__link::-webkit-scrollbar {
    height: 4px;
}
.notices__items {
    max-height: calc(100vh - 300px);
    list-style: none;
    padding: 0 10px 0 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}
.notices__item-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap
}
.notices__messageOpen {
    max-height: 1000px !important;
    border: 0 !important;
}
.notices__item-body {
    height: auto;
    max-height: 100px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.225);
    cursor: pointer;
    overflow: hidden !important;
    text-overflow: ellipsis;
    transition-duration: 0.1s;
}
.notices__item-body:hover {
    max-height: 105px ;
}
.notices__item-body:active {
    max-height: 110px ;
    box-shadow: none;
}
@media screen and (max-width: 620px) {
    .notices {
        width: 300px;
    }
}