.bidsHistory {
    width: 100%;
}

.list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: 200px;
    overflow-y: auto;
}


.bid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #868c9c;
    list-style: none;
    margin: 0;
}

.bidInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bidViolet {
    color: #623AE8;
    border: none;
}

.bidText {
    font-size: 20px;
    font-weight: 800;
    text-align: center;
}

.bidAmount {
    margin-left: 8px;
    font-weight: 800;
    font-size: 30px;
}

.mergeCell {
    grid-column: span 3;
    width: 100%;
    display: flex;
    align-items: start;
}

