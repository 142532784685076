.route-table .position {
	text-align: center;
	width: 20px;
}

.route-table .point-type,
.route-table .temperature,
.route-table .weight,
.route-table .volume {
	width: 40px;
}

.route-table .dt,
.route-table .loading-type,
.route-table .cargo-type,
.route-table .carcass,
.route-table .editor.weight,
.route-table .editor.volume
 {
	width: 75px;
}

.route-table td.editor:not(.address) {
	width: 120px;
}

.route-table .actions {
	width: 68px;
	text-align: center;	
}

.route-table .actions i {
	padding-right: 7px;
}

.route .add-links {
	float: right;
}

.route .add-links button {
	line-height: 1.1;
	margin-left: 10px;
}
