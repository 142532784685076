.ssp a.change-month {
	display: inline-block;
	margin: 0 10px 0 10px;
}

.ssp .decimal {
	min-width: 50px;
}

.ssp .plan.decimal,
.ssp .department,
.ssp th.sub-period,
.ssp th.plan-today {
	cursor: pointer;
}

.ssp .decimal .cell-input {
	width: 110px;
}

.ssp .decimal .double-cell-input {
	width: 55px;
}

.ssp .decimal.green {
	background-color: darkseagreen;
}

.ssp .decimal.yellow {
	background-color: lightyellow;
}

.ssp .decimal.red {
	background-color: palevioletred;
}

.ssp th {
	position: sticky;
	top: -1px;
	background: white;
	z-index: 1;
}

.ssp .card-body {
	max-height: 400px;
	padding: 0;
}

.ssp .double-cell {
	max-width: 45px;
	min-width: 45px;
}

.ssp .grid {
	display: grid;
	grid-template-rows: 45px;
}