.auction {
    width: 100%;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 8px;
}

.wrapper {
    width: 100%;
    max-width: 1000px;
}

.auctionTimer {
    width: 100%;
}

.auctionTimerBody {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
}

.timerTitle {
    margin: 0 !important;
}

.firstPrice {
    width: 100%;
}

.ndsType {
    margin-top: 8px;
}

.auctionActions {
    width: 100%;
}

.auctionApiError {
    margin-bottom: 16px;
    color: red;
    padding: 8px;
    border: 1px solid red;
    border-radius: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}

.auctionBids {
    margin-top: 16px;
}

.auctionButtonBottom {
    margin-top: 8px;
}

.auctionBidsHistory {
    margin-top: 8px;
}

.auctionRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    width: 100%;
}

.auctionButtons {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
}











