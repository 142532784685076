.AuditTable .table-bordered .noneBorder {
    border: 0 !important;
}

.AuditTable .table-bordered .noneBorderRight {
    border-right: 0 !important;
}

.AuditTable .table-bordered .noneBorderBottom {
    border-bottom: 0 !important;
}

.AuditTable .table-bordered .noneBorderLeftRight {
    border-right: 0 !important;
    border-left: 0 !important;
}

.AuditTable .table-bordered .noneBorderVert {
    border-top: 0 !important;
    border-bottom: 0 !important;
}

.AuditTable .table-bordered .borderRight {
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-left: 0 !important;
}

.AuditTable .table-bordered .borderLeft {
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-right: 0 !important;
}

.AuditTable table {
    table-layout: fixed;
}

.AuditTable .trActive {
    background-color: rgb(235, 159, 155) !important;
}

.sticky-column {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: white;
}

.AuditTable .fixedHeaderTable {
    position: fixed;
    top: 0;
    margin-right: 500px;
    background: rgb(248,249,252);
    z-index: 2;
}

.AuditTable .table-bordered,.AuditTable .table-bordered td,.AuditTable .table-bordered tr  {
    border: 1px solid rgb(0%, 0%, 0%, 0.50) !important
}