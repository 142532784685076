.justified-text {
    text-align: justify;
    font-size: 16px;
    margin-bottom: 12px;
}
.normal-text {
    text-align: justify;
    font-weight: normal;
    font-size: 16px;
}
.closed-div {
    text-align: left;
    font-weight: normal;
    font-size: 16px
}
