.select-filter-panel-transport {
    display: flex;
    margin-top: 20px;
    justify-content: center;
}

.select-filter-panel-cargo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 5px;
}

.submit-filters-button {
    margin-top: 30px;
    margin-left: 10px;
}

.operational-report-date-picker {
    width: 100%;
}

.general-report-date-picker {
    height: 37px;
}

.operational-report-date-picker:focus {
    outline: none;
    font-weight: bold;
}

.carrier-price-progress-bar {
    background-color: rgb(200, 200, 200);
    position: relative;
    height: 100%;
    white-space: nowrap;
}

.cargo-report-table-body-cell {
    max-width: 300px;
}

.cargo-report-table-body-cell::-webkit-scrollbar {
    display: none;
}

.operational-report-pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.logistic-panel {
    display: flex;
    justify-content: space-between;
}

.charts-row {
    display: flex;
    flex-wrap: wrap;
}