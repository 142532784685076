.timer {
    width: 100%;
    display: flex;
    align-items: center;
}

.timer__box {
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.timer__value {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
}

.timer__text {
    font-size: 14px;
    font-weight: 600;
}