.button {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapper {

}