.show-password-btn {
    background-color: #fff !important;
    border-left: none !important;
    border-color: #ced4da !important;
}

.show-password-btn:hover {
    background-color: #fff !important;
}

.show-password-btn:focus,.show-password-btn:active {
    outline: none !important;
    box-shadow: none !important;
}

.show-password-btn i {
    color: #bdbdbd;
}

.ses {
    color: #247fda !important;
}

.privacy {
    color: #247fda !important;
}

/*Убрать выделение жёлтым цветом при автозаполнении*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #ced4da;
    -webkit-text-fill-color: #6c757d;
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
}