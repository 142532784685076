.map-date-picker {
    height: 33px;
    width: 100%;
}

.map-date-picker:focus {
    outline: none;
}

.geo-datetime {
    font-weight: bold;
    font-size: small;
    text-align: right;
    width: 50%;
    display: grid;
    place-items: end;
}

#yandex-map {
    width: 100%;
    height: 100%;
}

#yandex-map-load-spinner {
    position: relative;
    margin-left: 50%;
    margin-top: 200px;
}

.period {
    display: flex;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}